/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://unpkg.com/tailwindcss/dist/base.css
 */
@tailwind base;
/**
 * Remove the default box-shadow for invalid elements to prevent
 * inputs in Livewire components showing with a
 * red border by default in Firefox.
 *
 * See: https://github.com/laravel-frontend-presets/tall/issues/7
 */
input:invalid, textarea:invalid, select:invalid {
  box-shadow: none;
}

/**
 * This injects any component classes registered by plugins.
 */
@tailwind components;
/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 */
/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 */
@tailwind utilities;
.rtl {
  direction: rtl;
  text-align: right;
}

.ck-editor__main h1, .ck-editor__main h2, .ck-editor__main h3, .ck-editor__main h4, .ck-editor__main h5, .ck-editor__main h6 {
  font-weight: 700;
}
.ck-editor__main h1 {
  font-size: 24px;
}
.ck-editor__main h2 {
  font-size: 22px;
}
.ck-editor__main h3 {
  font-size: 20px;
}
.ck-editor__main h4 {
  font-size: 18px;
}
.ck-editor__main h5 {
  font-size: 16px;
}
.ck-editor__main h6 {
  font-size: 14px;
}
.ck-editor__main p {
  margin-bottom: 10px;
}
.ck-editor__main ol, .ck-editor__main ul {
  padding: 5px 10px;
  margin: 0 20px;
}
.ck-editor__main ul {
  list-style: disc;
}
.ck-editor__main ol {
  list-style: decimal;
}
.ck-editor__main a {
  text-decoration: underline;
  color: mediumblue;
}

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 */
[x-cloak] {
  display: none;
}

.glass {
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}